import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Work from './pages/Work';
import Navbar from './Component/Navbar';
import Pricing from './pages/Pricing';
import Policy from './pages/Policy';
import WorkDetail from './pages/WorkDetail';
import PackageDetail from './pages/PackageDetail';
import ScrollToTop from './Component/ScrollToTop';

function App() {
  return (
    <div className="App ">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path='/' element={<Work />} />
          <Route path='/workdetail/:id' element={<WorkDetail />} />
          <Route path='/package-detail/:id' element={<PackageDetail />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/process' element={<Policy />} />


        </Routes>
      </Router>
    </div>
  );
}

export default App;
