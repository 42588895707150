import React from 'react';
import { Link } from 'react-router-dom'

const Navbar = () => {
    return (
        <div className='flex flex-col gap-[10px] justify-start items-start mt-[100px] ml-[20px] flex-wrap md:flex-row md:ml-[275px] md:items-center md:gap-[30px] '>
            <Link className='text-white hover:text-[#AA85FF]' to='/'>Work</Link>
            <Link className='text-white hover:text-[#AA85FF]' to='/pricing'>Pricing</Link>
            <Link className='text-white hover:text-[#AA85FF]' to='/process'>Process & Policy</Link>
        </div>
    )
}

export default Navbar
