//calculator project
import calcD1 from '../Assests/DesktopImages/Calculator/1.webp';
import calcD2 from '../Assests/DesktopImages/Calculator/2.webp';
import calcM1 from '../Assests/MobiileImages/Calculator/1.webp';
import calcM2 from '../Assests/MobiileImages/Calculator/2.webp';
import calcM3 from '../Assests/MobiileImages/Calculator/3.webp';
import calcCI from '../Assests/DesktopImages/Calculator/ci.webp';
// jain tours and travel 
import jCI from '../Assests/DesktopImages/JainTours/ci.webp';
import jD1 from '../Assests/DesktopImages/JainTours/1.webp';
import jD2 from '../Assests/DesktopImages/JainTours/2.webp';
import jD3 from '../Assests/DesktopImages/JainTours/3.webp';
import jD4 from '../Assests/DesktopImages/JainTours/4.webp';
import jD5 from '../Assests/DesktopImages/JainTours/5.webp';
import jM1 from '../Assests/MobiileImages/Jaintours/1.webp';
import jM2 from '../Assests/MobiileImages/Jaintours/2.webp';
import jM3 from '../Assests/MobiileImages/Jaintours/3.webp';
import jM4 from '../Assests/MobiileImages/Jaintours/4.png';
//Sujal arts
import sD1 from '../Assests/DesktopImages/Sujalarts/1.webp';
import sD2 from '../Assests/DesktopImages/Sujalarts/2.webp';
import sD3 from '../Assests/DesktopImages/Sujalarts/3.webp';
import sD4 from '../Assests/DesktopImages/Sujalarts/4.webp';
import sD5 from '../Assests/DesktopImages/Sujalarts/5.webp';
import sM1 from '../Assests/MobiileImages/Sujalarts/1.webp';
import sM2 from '../Assests/MobiileImages/Sujalarts/2.webp';
import sM3 from '../Assests/MobiileImages/Sujalarts/3.webp';
import sM4 from '../Assests/MobiileImages/Sujalarts/4.webp';
import sM5 from '../Assests/MobiileImages/Sujalarts/5.webp';
import sM6 from '../Assests/MobiileImages/Sujalarts/6.webp';
import sM7 from '../Assests/MobiileImages/Sujalarts/7.webp';
import sM8 from '../Assests/MobiileImages/Sujalarts/8.webp';
import sM9 from '../Assests/MobiileImages/Sujalarts/9.webp';
import sM10 from '../Assests/MobiileImages/Sujalarts/10.webp';
import sM11 from '../Assests/MobiileImages/Sujalarts/11.webp';
import sM12 from '../Assests/MobiileImages/Sujalarts/12.webp';
import sM13 from '../Assests/MobiileImages/Sujalarts/13.webp';
import sM14 from '../Assests/MobiileImages/Sujalarts/14.webp';
import sM15 from '../Assests/MobiileImages/Sujalarts/15.webp';
import sM16 from '../Assests/MobiileImages/Sujalarts/16.webp';
import sM17 from '../Assests/MobiileImages/Sujalarts/17.webp';
import sM18 from '../Assests/MobiileImages/Sujalarts/18.webp';
import sM19 from '../Assests/MobiileImages/Sujalarts/19.webp';
import sM20 from '../Assests/MobiileImages/Sujalarts/20.webp';
//rnvaastu consultant
import rCI from '../Assests/DesktopImages/Rnvaastu/ci.webp';
import rD1 from '../Assests/DesktopImages/Rnvaastu/1.webp';
import rD2 from '../Assests/DesktopImages/Rnvaastu/2.webp';
import rD3 from '../Assests/DesktopImages/Rnvaastu/3.webp';
import rD4 from '../Assests/DesktopImages/Rnvaastu/4.webp';
import rD5 from '../Assests/DesktopImages/Rnvaastu/5.webp';
import rM1 from '../Assests/MobiileImages/Rnvaastu/1.webp';
import rM2 from '../Assests/MobiileImages/Rnvaastu/2.webp';
import rM3 from '../Assests/MobiileImages/Rnvaastu/3.webp';
import rM4 from '../Assests/MobiileImages/Rnvaastu/4.webp';
import rM5 from '../Assests/MobiileImages/Rnvaastu/5.webp';
import rM6 from '../Assests/MobiileImages/Rnvaastu/6.webp';
import rM7 from '../Assests/MobiileImages/Rnvaastu/7.webp';
import rM8 from '../Assests/MobiileImages/Rnvaastu/8.webp';
import rM9 from '../Assests/MobiileImages/Rnvaastu/9.webp';
import rM10 from '../Assests/MobiileImages/Rnvaastu/10.webp';
import rM11 from '../Assests/MobiileImages/Rnvaastu/11.webp';
import rM12 from '../Assests/MobiileImages/Rnvaastu/12.webp';
import rM13 from '../Assests/MobiileImages/Rnvaastu/13.webp';
import rM14 from '../Assests/MobiileImages/Rnvaastu/14.webp';
import rM15 from '../Assests/MobiileImages/Rnvaastu/15.webp';
import rM16 from '../Assests/MobiileImages/Rnvaastu/16.webp';
import rM17 from '../Assests/MobiileImages/Rnvaastu/17.webp';





export const Media = {
    // Calc Images
    calcCI: calcCI,
    calcD1: calcD1,
    calcD2: calcD2,
    calcM1: calcM1,
    calcM2: calcM2,
    calcM3: calcM3,

    // J Images
    jCI: jCI,
    jD1: jD1,
    jD2: jD2,
    jD3: jD3,
    jD4: jD4,
    jD5: jD5,
    jM1: jM1,
    jM2: jM2,
    jM3: jM3,
    jM4: jM4,

    // S Images
    sD1: sD1,
    sD2: sD2,
    sD3: sD3,
    sD4: sD4,
    sD5: sD5,
    sM1: sM1,
    sM2: sM2,
    sM3: sM3,
    sM4: sM4,
    sM5: sM5,
    sM6: sM6,
    sM7: sM7,
    sM8: sM8,
    sM9: sM9,
    sM10: sM10,
    sM11: sM11,
    sM12: sM12,
    sM13: sM13,
    sM14: sM14,
    sM15: sM15,
    sM16: sM16,
    sM17: sM17,
    sM18: sM18,
    sM19: sM19,
    sM20: sM20,

    // R Images
    rCI: rCI,
    rD1: rD1,
    rD2: rD2,
    rD3: rD3,
    rD4: rD4,
    rD5: rD5,
    rM1: rM1,
    rM2: rM2,
    rM3: rM3,
    rM4: rM4,
    rM5: rM5,
    rM6: rM6,
    rM7: rM7,
    rM8: rM8,
    rM9: rM9,
    rM10: rM10,
    rM11: rM11,
    rM12: rM12,
    rM13: rM13,
    rM14: rM14,
    rM15: rM15,
    rM16: rM16,
    rM17: rM17,
};