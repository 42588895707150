import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Contact from '../Component/Contact';
import Techjoy from '../Component/Techjoy';
import PackageData from '../Api/Package.json'; // Corrected the import path
import { visitSite } from '../Functions/function';

const PackageDetail = () => {
    const { id } = useParams(); // Use useParams as a function
    const actualId = Number(id);
    const [pac, setPac] = useState(null);
    useEffect(() => {
        const packageDetail = PackageData.find(item => item.id === actualId);
        setPac(packageDetail);
    }, [actualId]); // Update dependency to actualId, not just id

    return (
        <div className='flex flex-col justify-end items-end w-full h-fit mt-[144px]'>
            <div className='md:w-3/4 w-11/12 h-fit flex flex-col justify-start items-start gap-[200px]'>

                {pac ? (
                    <div onClick={() => visitSite(`https://wa.me/+919860852466?text=${pac.name} for ${pac.price}`)} className='flex flex-col justify-start items-start bg-white rounded-3xl gap-[100px] w-11/12 pb-[100px] pt-[100px] pl-[34px] cursor-pointer'>
                        <div className='gap-2 flex flex-col'>
                            <h2 className='text-[#703BE7] md:text-[48.01px] text-[29.67px] '>{pac.name}</h2>
                            <h3 className='text-[#00BA07] md:text-[48.01px] text-[29.67px]'>{pac.price} /-</h3>
                        </div>

                        <div className='flex gap-1 flex-col w-2/3'>
                            <p className='md:text-[29.67px] text-[18.34px] text-[#0057FF]'>{pac.delivery}</p>
                            <p className='md:text-[29.67px] text-[18.34px] text-[#0057FF]'>Cancel Anytime</p>
                            <p className='md:text-[29.67px] text-[18.34px] text-[#0057FF]'>
                                Money Back Guarantee within 24 Hours <span className='md:text-[18.34px] text-[11.33px]'>(Terms and conditions applied)</span>
                            </p>
                        </div>

                        {/* inclusion  */}
                        <div className='flex flex-col gap-5'>
                            <h2 className='md:text-[29.67px] text-[18.34px] text-[#703BE7]'>Inclusion</h2>
                            <ul className='list-disc'>
                                {pac.inclusion && pac.inclusion.map((item, index) => (
                                    <li key={index} className='text-[#703BE7] md:text-[18.34px] text-[11.33px]'>{item}</li>
                                ))}
                            </ul>
                        </div>

                        {/* exclusion  */}
                        <div className='flex flex-col gap-5'>
                            <h2 className='md:text-[29.67px] text-[18.34px] text-[#703BE7]'>Exclusion</h2>
                            <ul className='list-disc'>
                                <li className='text-[#703BE7] md:text-[18.34px] text-[11.33px]'>{pac.exclusion}</li>
                            </ul>
                        </div>

                        <p className='text-[#703BE7] md:text-[18.34px] text-[11.33px] w-10/12 text-left'>
                            {pac.description}
                        </p>

                        <button onClick={() => visitSite(`https://wa.me/+919860852466?text=${pac.name} for ${pac.price}`)} className='bg-[#703BE7] flex justify-center items-center md:text-[18.34px] text-[11.33px] pl-[60px] pr-[60px] text-white rounded pt-[9px] pb-[9px]'>
                            Buy Now
                        </button>
                    </div>
                ) : (
                    <p>Loading package details...</p>
                )}

                {/* rock bottom  */}
                <Contact />
                <Techjoy />
            </div>
        </div>
    );
};

export default PackageDetail;
