import React from 'react'
import Contact from '../Component/Contact';
import Techjoy from '../Component/Techjoy';
import { useNavigate } from 'react-router-dom';
import { visitSite } from '../Functions/function';
import packageData from '../Api/Package.json';
import { Helmet } from 'react-helmet';

const Pricing = () => {

    const navigate = useNavigate();

    const handlePackageDetails = (id) => {
        navigate(`/package-detail/${id}`);
    }


    return (
        <>
            <Helmet>
                {/* General Meta Tags */}
                <title>Pricing | Techjoy - Website Development Packages</title>
                <meta name="description" content="Explore Techjoy's affordable and comprehensive web development packages, tailored to meet the needs of small businesses, enterprises, and large-scale projects. Find the perfect plan for your business today." />

                {/* Dynamic - Premium Package */}
                <meta name="title" content="Dynamic - Premium Package | Techjoy" />
                <meta name="description" content="Get a stunning, responsive website in just 3 weeks with Techjoy's Dynamic - Premium package. Perfect for informational websites without backend complexity, featuring custom designs, SEO, and lifetime maintenance support." />
                <meta name="keywords" content="Dynamic website package, Techjoy, website design, SEO, responsive design, social media integration, Google Analytics, fast hosting, custom design, animations, website without backend" />

                {/* Full-Stack - Luxury Package */}
                <meta name="title" content="Full-Stack - Luxury Package | Techjoy" />
                <meta name="description" content="Techjoy’s Full-Stack Luxury package offers a comprehensive website solution with user data management, e-commerce, custom API development, and more. Perfect for businesses seeking a dynamic website with all the bells and whistles." />
                <meta name="keywords" content="Full-stack website package, Techjoy, user authentication, e-commerce integration, advanced database management, custom API development, admin panel, blog integration, responsive design, CMS" />

                {/* Legend - Apex Package */}
                <meta name="title" content="Legend - Apex Package | Techjoy" />
                <meta name="description" content="Techjoy's ultimate website solution, the Legend - Apex package, is designed for large-scale projects. Includes AI-powered analytics, mobile app development, advanced security features, multi-language support, and more." />
                <meta name="keywords" content="Enterprise website package, Legend - Apex, Techjoy, AI analytics, mobile app development, advanced security, performance optimization, multi-language support, accessibility compliance, custom integrations, training sessions" />

                {/* Open Graph (Facebook) */}
                <meta property="og:title" content="Pricing | Techjoy - Website Development Packages" />
                <meta property="og:description" content="Browse through Techjoy’s dynamic, full-stack, and enterprise-level web development packages, and choose the best plan for your business growth." />
                <meta property="og:image" content="link_to_dynamic_package_image" />
                <meta property="og:url" content="https://techjoy.com/pricing" />
                <meta property="og:type" content="website" />

                {/* Twitter */}
                <meta name="twitter:title" content="Pricing | Techjoy - Website Development Packages" />
                <meta name="twitter:description" content="Explore Techjoy’s custom web development packages, offering dynamic, full-stack, and enterprise-level solutions. Perfect for all business sizes." />
                <meta name="twitter:image" content="link_to_dynamic_package_image" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className='flex flex-col justify-end items-end w-full h-fit mt-[144px]'>
                <div className='md:w-3/4 w-11/12 h-fit flex flex-col justify-start items-start gap-[200px]'>

                    <div className='flex flex-col gap-5'>



                        <div onClick={() => visitSite(`https://wa.me/919860852466?text=Put%20your%20details%0AName%20:%0AEmail%20:%0AWhatsapp%20Number%20:%20(This%20one)%0ADate%20:%0ATime%20:
`)} className='flex flex-col bg-white justify-center items-center pl-12 pr-12 pt-3 pb-3 rounded-lg cursor-pointer'>
                            <p className='text-[#703BE7] bg-white text-[18.34px]'>Book a Free Counsultancy</p>
                        </div>

                        <div className='flex flex-row flex-wrap gap-5'>

                            {
                                packageData.map((item, index) => (
                                    <div key={index} onClick={() => handlePackageDetails(item.id)} className='flex flex-col gap-5 justify-center items-center  bg-white w-11/12 md:w-[419px] h-fit rounded-3xl pt-10 pb-10 cursor-pointer'>
                                        <h2 className='text-[#703BE7] text-[29.67px] bg-white'>{item.name}</h2>
                                        <h2 className='text-[#00BA07] text-[48.01px] bg-white'>
                                            {item.price} /-
                                        </h2>
                                        <ul className='list-disc text-[#703BE7] ml-4 mt-[20px] w-4/5' >
                                            <li className='bg-whit md:text-[18.34px] text-[14.05px] bg-white' key={index}>{item.explain}</li>
                                        </ul>
                                        <button onClick={handlePackageDetails} className='flex justify-center items-center bg-[#703BE7] pl-7 pr-7 pt-3 pb-3 rounded-md text-white md:text-[18.34px] text-[14.05]'>
                                            All Details
                                        </button>
                                    </div>

                                ))
                            }
                        </div>


                    </div>

                    <Contact />
                    <Techjoy />

                    {/* rock bottom  */}
                </div >
            </div >
        </>

    )
}

export default Pricing
