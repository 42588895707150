import React from 'react';
import { useNavigate } from 'react-router-dom';
import workData from '../Api/Works.json';
import { Media } from '../Assests/Media';
import Techjoy from '../Component/Techjoy';
import Contact from '../Component/Contact';
import { Helmet } from 'react-helmet';

const Work = () => {
    const navigate = useNavigate();
    const handleNavigate = (id) => {
        navigate(`workdetail/${id}`)
    };
    return (
        <>
            <Helmet>
                {/* Page Title */}
                <title>TechJoy | Expert Web, App & Software Development Services</title>

                {/* Meta Description */}
                <meta
                    name="description"
                    content="TechJoy provides expert web, app, and custom software development services. Based in Bhiwandi, India, we deliver innovative digital solutions to clients worldwide."
                />

                {/* Meta Keywords */}
                <meta
                    name="keywords"
                    content="TechJoy, tech agency, web development, app development, custom software, digital solutions, tech services, IT consulting, software solutions, mobile apps, website design, e-commerce solutions, cloud computing, UI/UX design, software development, digital transformation, technology solutions, software engineering, cybersecurity services, AI development, machine learning solutions"
                />

                {/* Canonical URL */}
                <link rel="canonical" href="https://techjoy.in" />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="TechJoy | Expert Web, App & Software Development Services" />
                <meta
                    property="og:description"
                    content="TechJoy offers professional web, app, and custom software development services. Transform your business with our innovative and affordable digital solutions."
                />
                <meta property="og:url" content="https://techjoy.in" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://techjoy.in/public/favicon.ico" />
                <meta property="og:site_name" content="TechJoy" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:see_also" content="https://www.facebook.com/Techjoy" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="TechJoy | Expert Web, App & Software Development Services" />
                <meta
                    name="twitter:description"
                    content="TechJoy offers professional web, app, and custom software development services. Transform your business with our innovative and affordable digital solutions."
                />
                <meta name="twitter:image" content="https://techjoy.in/public/favicon.ico" />

                {/* Alternate Language and Regional URLs */}
                {[
                    'en-us',
                    'en-gb',
                    'en-in',
                    'en-au',
                    'en-ca',
                    'en-nz',
                    'en-sg',
                    'en-za',
                    'en-ie',
                    'en-ph',
                    'en-hk',
                    'en-mt',
                    'en-ke',
                    'en-nl',
                    'en-ug',
                    'en-gh',
                    'en-tz',
                    'en-ae',
                    'en-jp',
                    'en-eg',
                    'en-dz',
                    'en-om',
                    'en-qa',
                    'en-sa',
                    'en-jo',
                    'en-ma',
                    'en-ye',
                    'en-tn',
                    'en-bd',
                    'en-lk',
                    'en-mu',
                    'en-pk',
                    'en-my',
                    'en-id',
                    'en-vn',
                    'en-th',
                    'en-kr',
                    'en-cn',
                    'en-tw',
                    'en-az',
                    'en-kz',
                    'en-uz',
                    'en-tm',
                    'en-kg',
                    'en-ge',
                    'en-am',
                    'en-tr',
                    'en-ir',
                    'en-il',
                    'en-ps',
                ].map((lang) => (
                    <link key={lang} rel="alternate" href="https://techjoy.in" hreflang={lang} />
                ))}

                {/* Structured Data (JSON-LD) */}
                <script type="application/ld+json">
                    {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "TechJoy",
          "url": "https://techjoy.in",
          "logo": "https://techjoy.in/public/favicon.ico",
          "sameAs": [
            "https://www.instagram.com/TechJoy.in_/",
            "https://www.facebook.com/Techjoy"
          ],
          "description": "TechJoy offers expert web, app, and custom software development services. Based in Bhiwandi, India, we deliver innovative digital solutions to clients worldwide.",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Aadeswar Tower B wing Anjurphata road",
            "addressLocality": "Bhiwandi",
            "addressRegion": "MH",
            "postalCode": "400001",
            "addressCountry": "IN"
          },
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91 9860852466",
            "contactType": "Customer Service",
            "availableLanguage": ["English"]
          },
          "hasOfferCatalog": {
            "@type": "OfferCatalog",
            "name": "Development Services",
            "itemListElement": [
              {
                "@type": "Offer",
                "name": "Web Development",
                "description": "Professional web development services tailored to your business needs."
              },
              {
                "@type": "Offer",
                "name": "App Development",
                "description": "Custom mobile app development for iOS and Android platforms."
              },
              {
                "@type": "Offer",
                "name": "Custom Software Development",
                "description": "Bespoke software solutions to enhance your business operations."
              }
            ]
          }
        }
      `}
                </script>
            </Helmet>
            <div className='flex flex-col justify-end items-end w-full h-fit mt-[144px]'>
                <div className='md:w-3/4 w-11/12 h-fit flex flex-col justify-start items-start gap-[200px]'>

                    {/* main diiv  */}
                    <div className='w-full h-fit flex-col justify-center items-start gap-[18px]'>
                        {/* heading  */}
                        <h1 className='text-white underline pb-3 underline-offset-[12px] text-5xl mt-[200px] mb-[18px]'>Work</h1>
                        <div className='w-full lg:w-3/4 h-fit flex flex-row gap-5 flex-wrap justify-start items-center'>
                            {
                                workData && workData.map((item, index) => (
                                    <div key={index} onClick={() => handleNavigate(item.id)} className='w-11/12 md:w-[435px] bg-black aspect-square flex justify-center items-center overflow-hidden'>
                                        <img className='scale-125 hover:scale-150' src={Media[item.thumbnail]} alt={item.heading} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    {/* rock bottom  */}
                    <Contact />
                    <Techjoy />
                </div>
            </div>
        </>
    )
}

export default Work
