import React from 'react'
import { visitSite } from '../Functions/function'

const Contact = () => {
    return (
        <div className='flex flex-col gap-[30px]'>
            <h1 className='text-white md:text-[48px] text-[29.67px] underline underline-offset-[18px]'>
                Contact Us
            </h1>
            <div className='flex flex-row justify-start items-start md:gap-40 gap-20 flex-wrap w-full h-fit'>
                <p onClick={() => visitSite('tel:+919860852466')} className='text-white md:text-[29.65px] text-[18.33px] cursor-pointer' >Phone</p>

                <p onClick={() => visitSite('https://wa.me/919860852466?text=Hello Techjoy')} className='text-white md:text-[29.65px] text-[18.33px] cursor-pointer' >Whatsapp</p>

                <p onClick={() => visitSite('https://www.instagram.com/techjoy.in_/')} className='text-white md:text-[29.65px] text-[18.33px] cursor-pointer' >Instagram</p>

                <p onClick={() => visitSite('https://mail.google.com/mail/?view=cm&fs=1&to=lalitsoni.business@gmail.com&su=Hello&body=Hello%20Techjoy%20I%20Want%20to')} className='text-white md:text-[29.65px] text-[18.33px] cursor-pointer' >Mail</p>

            </div>
        </div>
    )
}

export default Contact
