import React, { useEffect, useState } from 'react';
import workData from '../Api/Works.json';
import { useParams } from 'react-router-dom';
import { Media } from '../Assests/Media';
import Contact from '../Component/Contact';
import { visitSite } from '../Functions/function';
import Techjoy from '../Component/Techjoy';

const WorkDetail = () => {
    const { id } = useParams();
    const actualId = Number(id);
    const [project, setProject] = useState(null);




    //yutube video link convertor 

    const convertYoutubeUrlToEmbed = (url) => {
        if (!url) {
            return "";
        }
        // Extract the video ID from the URL
        const urlObj = new URL(url);
        const videoId = urlObj.searchParams.get('v');

        // Construct the embed link
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;

        return embedUrl;
    }


    useEffect(() => {
        const foundedProject = workData.find(item => item.id === actualId);
        setProject(foundedProject);
    }, [actualId]);

    // Helper function to get image from Media
    const getImage = (key) => {
        if (Media[key]) {
            return Media[key];
        } else {
            console.error(`Image key "${key}" not found in Media.`);
            return Media.default; // Use the default image
        }
    };

    if (!project) {
        return (
            <div className='flex flex-col justify-center items-center w-full h-full mt-[144px]'>
                <h1 className='text-white text-2xl'>Project not found</h1>
            </div>
        );
    }

    return (
        <div className='flex flex-col justify-end items-center w-full h-fit mt-[144px]'>
            <div className='md:w-3/4 w-11/12 h-fit flex flex-col justify-center items-start gap-[200px]'>
                <h1 className='text-white md:text-[48px] text-[29.67px] underline underline-offset-[18px]'>
                    {project.heading}
                </h1>



                {/* Mobile images container */}
                <div>

                    <h1 className='text-white md:text-[29.67px] text-[18.34px] underline underline-offset-[18px] mt-[69px]'>Mobile View</h1>
                    <div className='w-3/4 overflow-x-auto scrollbar-hide mt-10'>
                        <div className='flex flex-row gap-5 h-fit w-max'>
                            {project.mobile && project.mobile.length > 0 ? (
                                project.mobile.map((item, index) => (
                                    <div key={index} className='bg-white w-[265.76px] h-fit overflow-hidden rounded-[29px]'>
                                        <img
                                            className='w-full'
                                            src={getImage(item)}
                                            alt={`${project.heading} mobile view ${index + 1}`}
                                            onError={(e) => { e.target.src = Media.default; }}
                                        />
                                    </div>
                                ))
                            ) : (
                                <p className='text-white'>No mobile images available</p>
                            )}
                        </div>
                    </div>
                </div>
                {/* desktop images container */}
                <div>

                    <h1 className='text-white md:text-[29.67px] text-[18.34px] underline underline-offset-[18px] mt-[69px]'>Desktop View</h1>
                    <div className='w-3/4 overflow-x-auto scrollbar-hide mt-10'>
                        <div className='flex flex-row gap-5 h-fit w-max'>
                            {project.desktop && project.desktop.length > 0 ? (
                                project.desktop.map((item, index) => (
                                    <div key={index} className='bg-white md:w-[889.99px] w-[321.86px] h-fit overflow-hidden'>
                                        <img
                                            className='w-full'
                                            src={getImage(item)}
                                            alt={`${project.heading} mobile view ${index + 1}`}
                                            onError={(e) => { e.target.src = Media.default; }}
                                        />
                                    </div>
                                ))
                            ) : (
                                <p className='text-white'>No mobile images available</p>
                            )}
                        </div>
                    </div>
                </div>

                {/* Client name and image */}
                <div className='flex flex-col gap-[19px] justify-start items-start'>
                    <h1 className='text-white md:text-[29.67px] text-[18.34px] underline underline-offset-[18px] mt-[69px] mb-4'>
                        Client Name
                    </h1>
                    <div className='flex justify-center items-center overflow-hidden w-[241.79px] aspect-square bg-white'>
                        <img
                            className='w-full scale-150'
                            src={getImage(project.clientImage)}
                            alt={project.clientName}
                            onError={(e) => { e.target.src = Media.default; }}
                        />
                    </div>
                    <h1 className='text-white md:text-[18.34px] text-[11.33px]'>
                        {project.clientName}
                    </h1>
                </div>

                {/* CLIIENT VIDEO INTERVIEW  */}





                {/* Visit site */}
                <div className='flex flex-col gap-[19px] justify-start items-start'>
                    <h1 className='text-white md:text-[29.67px] text-[18.34px] underline underline-offset-[18px] mt-[69px] mb-4'>
                        Visit Site
                    </h1>
                    <h1 onClick={() => visitSite(project.visitSite)} className='text-white md:text-[18.34px] text-[11.33px] cursor-pointer'>
                        {project.heading}
                    </h1>
                </div>

                {/* Project details */}
                <div className='flex flex-col gap-[19px] justify-start items-start'>
                    <h1 className='text-white md:text-[29.67px] text-[18.34px] underline underline-offset-[18px] mt-[69px] mb-4'>
                        Project Detail
                    </h1>
                    <h1 className='text-white md:text-[18.34px] text-[11.33px] cursor-pointer'>
                        {project.projectDetails}
                    </h1>
                </div>

                <Contact />
                <Techjoy />

                {/* rock bottom  */}
            </div>
        </div>
    );
}

export default WorkDetail;